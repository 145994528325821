import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';

const NumberBadgeWrapper = styled('span')`
  display: block;
  position: absolute;
  right: -8px;
  top: -2px;
  width: 18px;
  height: 18px;
  font-size: 0.9rem;
  background: ${theme.colors.buy};
  color: ${theme.colors.white};
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme.below.lg} {
    width: 15px;
    height: 15px;
    font-size: 10px;
    right: -6px;
    top: -4px;
  }
  > span {
    display: block;
  }
`;

export const NumberBadge = ({ text, color, background }) => {
  return (
    <NumberBadgeWrapper
      className="number-badge"
      color={color}
      background={background}
    >
      <span>{text}</span>
    </NumberBadgeWrapper>
  );
};
