import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { theme } from '../../../Theming/Theming';
import { FlyoutTitle, NoResultTitle } from './SearchFlyout';
import { config } from '../../../../shop.config.custom';

const CatList = styled('ul')`
  margin-bottom: 1rem;
`;

const CatItem = styled('li')`
  padding: 0.3rem 0.2rem;
  &:hover {
    background: ${theme.colors.greys[0]};
  }
`;

const CatItemName = styled('div')`
  display: flex;
  h4 {
    font-size: 0.9rem;
    font-weight: ${theme.weights.bold};
  }
  span {
    color: #ccc;
    margin-left: 10px;
    font-size: 0.9rem;
    display: none;
  }
`;

const CategoryList = ({ categories, closeSearch, hideTarget }) => (
  <CatList>
    {categories.map(category => (
      <CatItem key={category.id} className="search-category">
        <CategoryLink
          category={category}
          onClose={() => {
            closeSearch();
            hideTarget();
          }}
        >
          <CatItemName>
            <h4>{category.name}</h4>
            <span>{category.primaryRoute.path}</span>
          </CatItemName>
        </CategoryLink>
      </CatItem>
    ))}
  </CatList>
);

const SearchFlyoutCategories = ({ searchProps, hideTarget, className }) => {
  const closeSearch = searchProps.closeSearch;
  const categories =
    (searchProps.result &&
      searchProps.result.categories &&
      searchProps.result.categories.result) ||
    [];

  let articles = [],
    standards = [];
  categories.forEach(category => {
    if (category.primaryRoute.path.search(config.routes.blog.path) !== -1)
      articles.push(category);
    else standards.push(category);
  });

  return (
    <Fragment>
      <div className={className}>
        <FlyoutTitle>{t('Categories')}</FlyoutTitle>
        {standards && standards.length > 0 ? (
          <CategoryList
            categories={standards}
            hideTarget={hideTarget}
            closeSearch={closeSearch}
          />
        ) : (
          <NoResultTitle>{t('No results')}</NoResultTitle>
        )}
        <FlyoutTitle>{t('Blog Articles')}</FlyoutTitle>
        {articles && articles.length > 0 ? (
          <CategoryList
            categories={articles}
            hideTarget={hideTarget}
            closeSearch={closeSearch}
          />
        ) : (
          <NoResultTitle>{t('No results')}</NoResultTitle>
        )}
      </div>
    </Fragment>
  );
};

export default SearchFlyoutCategories;
