import React from 'react';
import { useLocation } from 'react-router';
import qs from 'qs';
import loadable from '@loadable/component';
import LoadingPage from '../LoadingPage';
import { config } from '../../shop.config.custom';
import { theme } from '../Theming/Theming';

export const LoadableStandardCategoryPage = loadable(
  () => import('./StandardCategoryPage'),
  {
    fallback: <LoadingPage />,
  }
);

export const LoadableWindowedCategoryPage = loadable(
  () => import('./WindowedCategoryPage'),
  {
    fallback: <LoadingPage />,
  }
);

const CategoryPage = (props) => {
  const location = useLocation();
  const { search } = location;

  if (config.routes.category.hide) return null;

  const searchObject = qs.parse(search, { ignoreQueryPrefix: true });
  const standardPagination =
    searchObject.standardPagination === 'true' || theme.productGrid.pagination;

  if (standardPagination) {
    return <LoadableStandardCategoryPage {...props} />;
  } else {
    return <LoadableWindowedCategoryPage {...props} />;
  }
};

export default CategoryPage;
