import React, { useRef } from 'react';
import { styled } from 'linaria/react';
import SearchAutoCompleteContainer from '@jetshop/ui/Search/SearchAutoCompleteContainer';
import SearchInput from '../../../../components/Layout/Header/Search/SearchInput';
import SearchFlyout from '../../../../components/Layout/Header/Search/SearchFlyout';
import UICloseButton from '../../../ui/CloseButton';
import autoCompleteQuery from './AutoCompleteQuery.gql';
import SearchIcon from '../../../ui/icons/SearchIcon';
import { theme } from '../../../Theming/Theming';
import useClickOutside from '../../../ui/hooks/useClickOutside';

export const SearchWrapper = styled('div')`
  position: relative;
  .search-field {
    padding-right: 4rem;
    height: 100%;
    .input-wrapper {
      height: 100%;
    }
  }
`;

const buttonCss = `
  display: flex;
  margin: 0;
  padding: 0;
  right: 1rem;
  top: 0;
  position: absolute;
  height: 100%;
  color: #333;
  opacity: 0.9;
  align-items: center;
  :focus,
  active {
    outline: none;
  }
  &:hover {
    opacity: 1;
  }
`;

export const CloseButton = styled(UICloseButton)`
  ${buttonCss}
  svg {
    width: 15px;
    height: 15px;
  }
`;

export const SubmitButton = styled('button')`
  ${buttonCss}
  svg {
    font-size: 1.5rem;
  }
  right: 3rem;
`;

export const Wrapper = styled('div')`
  ${theme.below.md} {
    height: 100%;
  }
`;

const SearchField = ({
  onCancel = () => {},
  setSearch,
  searchOpen,
  scrolling,
}) => {
  const innerRef = useRef();
  useClickOutside({
    innerRef,
    close: onCancel,
  });
  return (
    <SearchAutoCompleteContainer
      onSubmit={onCancel}
      onCancel={onCancel}
      focusOnLoad={true}
      autocompleteQuery={autoCompleteQuery}
    >
      {({
        getInputProps,
        getFlyoutProps,
        getCancelProps,
        updateFocus,
        triggerSearch,
        ...rest
      }) => {
        return (
          <SearchWrapper ref={innerRef} className="search-wrapper">
            <div className="search-field">
              <div
                className="input-wrapper"
                onClick={() => {
                  setSearch(true);
                }}
              >
                <SearchInput getInputProps={getInputProps} {...rest} />
              </div>
              <SubmitButton
                className="submit-search"
                onClick={() => {
                  triggerSearch();
                }}
              >
                <SearchIcon />
              </SubmitButton>
            </div>
            <CloseButton className="close-search" onClick={onCancel} />
            {searchOpen && (
              <SearchFlyout
                getFlyoutProps={getFlyoutProps}
                closeSearch={onCancel}
                triggerSearch={triggerSearch}
                scrolling={scrolling}
                {...rest}
              />
            )}
          </SearchWrapper>
        );
      }}
    </SearchAutoCompleteContainer>
  );
};

export default SearchField;
