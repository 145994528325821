import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { theme } from '../Theming/Theming';
import { Price } from '../Price';
import RemoveFromCart from '@jetshop/core/components/Mutation/RemoveFromCart';
import DecrementQuantity from '@jetshop/core/components/Mutation/DecrementQuantity';
import IncrementQuantity from '@jetshop/core/components/Mutation/IncrementQuantity';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import CloseButton from '../ui/CloseButton';
import ProductLink from '@jetshop/ui/ProductLink';
import cartQuery from './queries/cartQuery.gql';
import MinusIcon from '../ui/icons/MinusIcon';
import PlusIcon from '../ui/icons/PlusIcon';

const Wrapper = styled('div')`
  display: flex;
  background: white;
  padding: 0 0 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${theme.colors.border};
  position: relative;
`;

const ProductName = styled('h4')`
  color: #555;
  padding-right: 2rem;
`;

const SubName = styled('p')`
  color: #777;
`;

const ProductVariant = styled('p')`
  color: #828282;
  font-size: 0.85rem;
`;

const ProductComments = styled('div')`
  color: #828282;
  font-size: 0.85rem;
  margin-top: 5px;
`;

const Comment = styled('div')`
  .value {
    margin-left: 5px;
  }
`;

const ProductImage = styled('div')`
  width: 25%;
`;

const ProductDetail = styled('section')`
  width: 75%;
  padding-left: 1rem;
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'top top' 'bottomleft bottomright' 'bottom bottom';
`;

const ItemDetails = styled('div')`
  grid-area: top;
`;

const PriceWrapper = styled('div')`
  grid-area: bottomright;
`;

const LinePrice = styled(Price)`
  margin-top: 1rem;
  text-align: right;
`;

const AdjustQty = styled('div')`
  margin-top: 1rem;
  display: flex;
  grid-area: bottomleft;
  /* Plus/minus buttons */
  button {
    background: #f2f2f2;
    color: black;
    height: 20px;
    width: 20px;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    :focus {
      outline: none;
    }
    svg {
      color: #333;
      font-size: 0.9rem;
    }
    i {
      display: flex !important;
    }
  }
  /* Count */
  span {
    margin: 0 0.5rem;
    display: inline-block;
    line-height: 20px;
  }
`;

const RemoveItem = styled('div')`
  position: absolute;
  top: 2px;
  right: 0;
  z-index: 1;
  button {
    display: inline-block;
    position: relative;
    top: 0;
    padding: 3px;
    margin: -3px;
  }
  svg {
    use {
      fill: #999 !important;
    }
  }
`;

const CartItem = ({ item, className = '', close }) => {
  const { isVariant, variantImage, variantValues, hasVariantImage } =
    getCartItemVariant(item);
  return (
    <Wrapper className={className}>
      <ProductImage>
        {item.product.images.length > 0 && (
          <ProductLink product={item.product} onClick={close}>
            <Image
              aspect="3:3"
              sizes={[200]}
              src={
                hasVariantImage ? variantImage.url : item.product.images[0].url
              }
              alt={
                hasVariantImage ? variantImage.alt : item.product.images[0].alt
              }
            />
          </ProductLink>
        )}
      </ProductImage>
      <ProductDetail>
        <ItemDetails>
          <ProductLink product={item.product} onClick={close}>
            <ProductName>{item.product.name}</ProductName>
          </ProductLink>
          <SubName>{item.product.subName}</SubName>
          {isVariant && (
            <ProductVariant>{variantValues.join(', ')}</ProductVariant>
          )}
          <ProductComments>
            {item.customerComments.map((comment, index) => {
              return (
                <Comment key={index}>
                  <span className="name">{comment.name}:</span>
                  <span
                    className="value"
                    dangerouslySetInnerHTML={{ __html: comment.value }}
                  />
                </Comment>
              );
            })}
          </ProductComments>
        </ItemDetails>

        <RemoveItem>
          <RemoveFromCart cartQuery={cartQuery}>
            {(removeFromCart) => (
              <CloseButton
                onClick={() => removeFromCart(item.id, item.product)}
                size={24}
              />
            )}
          </RemoveFromCart>
        </RemoveItem>

        <AdjustQty>
          <DecrementQuantity cartQuery={cartQuery}>
            {(decrementQuantity) => (
              <button
                disabled={item.quantity === 1}
                onClick={() =>
                  item.quantity !== 1 && decrementQuantity(item.id)
                }
              >
                <MinusIcon />
              </button>
            )}
          </DecrementQuantity>
          <span>{item.quantity}</span>
          <IncrementQuantity cartQuery={cartQuery}>
            {(incrementQuantity) => (
              <button onClick={() => incrementQuantity(item.id)}>
                <PlusIcon />
              </button>
            )}
          </IncrementQuantity>
        </AdjustQty>
        <PriceWrapper>
          <LinePrice price={item.total} />
        </PriceWrapper>
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
