import { useProductList } from '@jetshop/core/hooks/ProductList';
import React from 'react';
import { styled } from 'linaria/react';
import FavoriteIcon from '../ui/icons/FavouriteIcon';
import { theme } from '../Theming/Theming';

const FavouriteWrapper = styled('div')`
  z-index: 1;
  top: -1px;
  right: 0;
  ${theme.above.md} {
    //bottom: 0;
  }
  button {
    background: transparent;
    svg {
      font-size: 1.2rem;
      &.active {
        color: ${theme.colors.buy};
      }
      ${theme.above.md} {
        font-size: 1.4rem;
      }
    }
  }
`;

export function Favourite({ product, variant, listId, ...rest }) {
  const articleNumber = product.articleNumber;

  const selectedVariation = product.variant || variant;
  const variantArticleNumber = selectedVariation?.articleNumber;

  const { toggle, inList } = useProductList(listId);

  function toggleWithoutBubble(e) {
    e.preventDefault();
    toggle(articleNumber, {
      variantArticleNumber,
      productName: product.name,
    });
  }

  const isInList = inList(articleNumber, {
    variantArticleNumber,
  });

  return (
    <FavouriteWrapper {...rest}>
      <button onClick={toggleWithoutBubble} aria-label="Add to favourites">
        <FavoriteIcon
          className={isInList ? 'active' : 'inactive'}
          active={isInList}
        />
      </button>
    </FavouriteWrapper>
  );
}
