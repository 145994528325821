import React from 'react';

export default function useCloseOutside({ innerRef, close }) {
  React.useEffect(() => {
    //CLOSE OUTSIDE LISTENER
    const listener = e => {
      if (!innerRef.current) return;
      if (!innerRef.current.contains(e.target)) {
        close();
      }
    };
    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [close, innerRef]);
}
