import ChannelContext from '@jetshop/core/components/ChannelContext/ChannelContext';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import { theme } from '../../Theming/Theming';
import MaxWidth from '../MaxWidth';
import NewsletterBox from './Newsletter/NewsletterBox';

const FooterWrapper = styled('footer')`
  margin-top: 3%;
  background-image: linear-gradient(
    to bottom right,
    ${theme.colors.link},
    ${theme.footer.background},
    ${theme.colors.link}
  );
  z-index: 2;
  a {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    a {
    }
  }
  .footer-content {
    padding: 0 0;
    color: ${theme.footer.color};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .footer-top {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem 0;
    ${theme.above.lg} {
      flex-wrap: nowrap;
    }
    > div {
      width: 100%;
      padding: 1rem;
      text-align: center;
      ${theme.above.md} {
        width: 50%;
      }
      h3 {
        margin-bottom: 8px;
        word-break: keep-all;
      }
      img {
        margin-bottom: 6px;
        max-width: 40px;
      }
    }
  }
  .footer-bottom {
    width: 100%;
    max-width: 1280px;  
      ${theme.above.md} {
        flex-direction: row;
      }
      ${theme.above.lg} {
        
      }
        ${theme.below.md} {
          width: 100%;
          margin: 1rem 0;
        }
        ${theme.above.lg} {
          &:first-child {
            justify-content: flex-start;
          }
        }
      }
    img {
      max-width: 100%;
      height: auto;
    }
    .partners {
      text-align: center;
      padding: 0.5rem;
      ${theme.above.md} { 
        padding: 3rem 0 4rem;
      }
      img {
        height: 18px;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        ${theme.above.md} {
          height: 40px;
          margin-right: 32px;
          margin-top: 12px;
        }
      }
    }
    
    }
  }
`;

export const FooterContentContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterContentWrapper = styled('div')`
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 1rem;
  padding-top: 0.5rem;
  flex-direction: column;
  ${theme.above.sm} {
    flex-direction: row;
  }
  > div {
    width: 100%;
    padding: 2rem 0;
    text-align: center;
    &:last-child {
      padding-bottom: 3rem;
    }
    ${theme.above.md} {
      width: 50%;
    }
    ${theme.above.lg} {
      width: 25%;
      padding: 3rem 3rem;
    }
    > h3 {
      margin-bottom: 8px;
      word-break: auto-phrase;
    }
    > ul {
      > li {
        margin-bottom: 6px;
        img {
          display: none;
        }
      }
    }
    &:last-child {
      > ul {
        > li {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1em;
          > img {
            padding-right: 4px;
            display: block;
          }
        }
      }
    }
    p {
      line-height: 1.7em;
      margin-bottom: 1rem;
    }
  }
`;

export const FooterBottomContainer = styled('div')`
  background: green;
  display: flex;
`;

const Footer = () => {
  const { footer1, footer2, footer3, footer4, footerTop } =
    useContext(SiteContentContext);
  const { selectedChannel } = useContext(ChannelContext);

  const deliveryServiceIconPath = () => {
    const { code } = selectedChannel.country;
    const dpdCountries = ['BE', 'NL', 'DE'];

    return dpdCountries.includes(code) ? '/dpd.png' : '/postnord.png';
  };

  return (
    <>
      <FooterWrapper>
        <div className="newsletter-box">
          <NewsletterBox />
        </div>

        <div className="footer-content">
          <div className="footer-top">{footerTop}</div>
          <FooterContentContainer>
            <FooterContentWrapper>
              <div>{footer1}</div>
              <div>{footer2}</div>
              <div>{footer3}</div>
              <div>{footer4}</div>
            </FooterContentWrapper>
          </FooterContentContainer>
          <div className="footer-bottom">
            <MaxWidth>
              <div className="partners">
                <img src="/klarna.png" alt="Klarna logo" />
                <img src="/visa.png" alt="Visa logo" />

                <img src="/mastercard.png" alt="mastercard" />
                <img src={deliveryServiceIconPath()} alt="freight logo" />
              </div>
            </MaxWidth>
          </div>
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;
