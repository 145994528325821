import { theme } from './Theming';

export const getFontSize = props => {
  if (props.length === 0) return null;
  return (
    'font-size:' +
    props[0] +
    ';' +
    theme.above.md +
    '{font-size:' +
    props[1] +
    ';}' +
    theme.above.lg +
    '{font-size:' +
    props[2] +
    ';}' +
    theme.above.xl +
    '{font-size:' +
    props[3] +
    ';}'
  );
};

export const createBreakPoints = function(
  labels,
  breakpoints,
  mediaString = false
) {
  let breakpointObject = {
    above: {},
    below: {},
    only: {}
  };
  labels.forEach((label, index) => {
    breakpointObject['above'][label] =
      (mediaString ? `@media` : ``) + `(min-width: ${breakpoints[index]})`;
    breakpointObject['below'][label] =
      (mediaString ? `@media` : ``) + `(max-width: ${breakpoints[index + 1]})`;
    breakpointObject['only'][label] =
      (mediaString ? `@media` : ``) +
      `(min-width: ${breakpoints[index]}) and (max-width: ${
        breakpoints[index + 1]
      })`;
  });
  return breakpointObject;
};
