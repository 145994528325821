import React, { useContext } from 'react';
import Image from '@jetshop/ui/Image';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { getChannel } from '../../../shop.config.custom';

const Logo = ({ size = [400] }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const channel = getChannel(selectedChannel.name);
  const src = `/pub_images/original/${channel.logo}`;
  return <Image src={src} sizes={size} aspect={'300:100'} crop={false} />;
};

export default Logo;
